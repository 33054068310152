import React from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate, useLocation } from 'react-router-dom';
import LoginPage from './pages/LoginPage';
import DashboardPage from './pages/DashboardPage';
import Header from './components/Header';
import GroupesPage from './pages/GroupesPage';
import UtilisateursPage from './pages/UtilisateursPage';
import DemandesPage from './pages/DemandesPage';
import AdressesFacturationPage from './pages/AdressesFacturationPage';
import Login from './components/Login';

const App = () => {
  const location = useLocation();
  const isLoginPage = location.pathname === '/login';

  return (
    <div>
      {!isLoginPage && <Header />}
      <Routes>
        <Route path="/login" element={<Login />} />
        <Route path="/demandes" element={<DemandesPage />} /> 
        <Route path="/dashboard" element={<DashboardPage />} />
        <Route path="/groupes" element={<GroupesPage />} />
        <Route path="/utilisateurs" element={<UtilisateursPage />} />
       
        <Route path="/adresses" element={<AdressesFacturationPage />} /> 
        <Route path="*" element={<Navigate to="/login" />} />
      </Routes>
    </div>
  );
};

const MainApp = () => {
  return (
    <Router>
      <App />
    </Router>
  );
};

export default MainApp;
