import React, { useEffect, useState, useCallback } from 'react';
import {
  AppBar,
  Toolbar,
  Container,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TablePagination,
  Box
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { getDemandes } from '../models/demande';
import { getGroups } from '../models/group';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { green } from '@mui/material/colors';

// Composant pour afficher le logo dans l'AppBar
const Logo = styled('img')({
  height: 40,
  marginRight: 16,
});

const DemandesPage = () => {
  const [demandes, setDemandes] = useState([]);
  const [groups, setGroups] = useState([]);
  const [selectedGroup, setSelectedGroup] = useState('');
  const [page, setPage] = useState(0);
  const [rowsPerPage] = useState(20);
  const user = JSON.parse(localStorage.getItem('user'));

  const fetchDemandes = useCallback(async () => {
    const data = await getDemandes(user.id_societe);
    setDemandes(data);
  }, [user.id_societe]);

  const fetchGroups = useCallback(async () => {
    const data = await getGroups();
    console.log(data);
    setGroups(data);
    if (user.droit === 'admin') {
      console.log(user);
      const userGroup = data.find(group => group.nom === user.groupe);
      if (userGroup) {
        setSelectedGroup(userGroup.id);
      } else {
        console.error("Group not found for the admin user");
      }
    }
  }, [user.droit, user.groupe]);

  useEffect(() => {
    fetchDemandes();
    fetchGroups();
  }, [fetchDemandes, fetchGroups]);

  const handleGroupChange = (e) => {
    setSelectedGroup(e.target.value);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const filteredDemandes = selectedGroup
    ? demandes.filter(demande => demande.id_groupe === selectedGroup)
    : demandes;

  const totalDemandes = filteredDemandes.length;
  const totalVisasTraites = filteredDemandes.filter(demande => demande.etat_visa === 'traité').length;

  return (
    <>
      <AppBar position="static" color="primary">
       
      </AppBar>
      <Container sx={{ marginTop: 4 }}>
        <Typography variant="h4" gutterBottom>
          Demandes
        </Typography>
        <Box
          sx={{
            backgroundColor: '#e3f2fd',
            padding: 2,
            borderRadius: 2,
            marginBottom: 2
          }}
        >
          <Typography variant="subtitle1">
            Nombre total de demandes : {totalDemandes} | Nombre de visas traités : {totalVisasTraites}
          </Typography>
        </Box>
        {user.droit !== 'admin' && (
          <FormControl margin="normal" fullWidth>
            <InputLabel>Groupe</InputLabel>
            <Select
              value={selectedGroup}
              onChange={handleGroupChange}
              label="Groupe"
            >
              <MenuItem value="">Tous les groupes</MenuItem>
              {groups.map((group) => (
                <MenuItem key={group.id} value={group.id}>
                  {group.nom}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        )}
        <TableContainer component={Paper} sx={{ marginTop: 2 }}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell sx={{ backgroundColor: 'primary.main', color: 'white' }}>ID</TableCell>
                <TableCell sx={{ backgroundColor: 'primary.main', color: 'white' }}>Date de Demande</TableCell>
                <TableCell sx={{ backgroundColor: 'primary.main', color: 'white' }}>Date de Départ</TableCell>
                <TableCell sx={{ backgroundColor: 'primary.main', color: 'white' }}>Pays</TableCell>
                <TableCell sx={{ backgroundColor: 'primary.main', color: 'white' }}>Prix</TableCell>
                <TableCell sx={{ backgroundColor: 'primary.main', color: 'white' }}>Nom</TableCell>
                <TableCell sx={{ backgroundColor: 'primary.main', color: 'white' }}>Prénom</TableCell>
                <TableCell sx={{ backgroundColor: 'primary.main', color: 'white' }}>Téléphone</TableCell>
                <TableCell sx={{ backgroundColor: 'primary.main', color: 'white' }}>Visa</TableCell>
                <TableCell sx={{ backgroundColor: 'primary.main', color: 'white' }}>Groupe</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {filteredDemandes
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((demande) => (
                  <TableRow
                    key={demande.id}
                    sx={{
                      backgroundColor: demande.etat_paiement === 'payé' ? green[100] : 'inherit',
                      '&:hover': { backgroundColor: 'action.hover' }
                    }}
                  >
                    <TableCell>{demande.id}</TableCell>
                    <TableCell>{new Date(demande.date_creation).toLocaleDateString()}</TableCell>
                    <TableCell>{new Date(demande.date_depart).toLocaleDateString()}</TableCell>
                    <TableCell>{demande.libelle_visa}</TableCell>
                    <TableCell>{demande.prix_total} €</TableCell>
                    <TableCell>{demande.nom}</TableCell>
                    <TableCell>{demande.prenom}</TableCell>
                    <TableCell>{demande.telephone}</TableCell>
                    <TableCell>
                      {demande.etat_visa === 'traité' && <CheckCircleIcon color="primary" />}
                    </TableCell>
                    <TableCell>{demande.groupe_nom}</TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[20]}
          component="div"
          count={filteredDemandes.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
        />
      </Container>
    </>
  );
};

export default DemandesPage;
