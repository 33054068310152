import React, { useEffect, useState } from 'react';
import { Container, Typography, Box, Button, TextField, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@mui/material';
import { getGroups, createGroup, updateGroup, deleteGroup } from '../models/group';

const GroupesPage = () => {
  const [groups, setGroups] = useState([]);
  const [newGroup, setNewGroup] = useState({ id_societe: '', nom: '' });
  const [editGroup, setEditGroup] = useState(null);
  const user = JSON.parse(localStorage.getItem('user'));
  console.log(user);

  useEffect(() => {
    fetchGroups();
  }, []);

  const fetchGroups = async () => {
    const data = await getGroups();
    setGroups(data);
  };

  const handleCreate = async () => {
    await createGroup({ ...newGroup, id_societe: user.id_societe });
    setNewGroup({ id_societe: '', nom: '' });
    fetchGroups();
  };

  const handleUpdate = async (id) => {
    await updateGroup(id, { ...editGroup, id_societe: user.id_societe });
    setEditGroup(null);
    fetchGroups();
  };

  const handleDelete = async (id) => {
    if (window.confirm("Êtes-vous sûr de vouloir supprimer ce groupe ?")) {
      await deleteGroup(id);
      fetchGroups();
    }
  };

  return (
    <Container>
      <Typography variant="h4" gutterBottom>Groupes</Typography>
      <Box display="flex" flexDirection="column" alignItems="center">
        <TextField
          label="Nom"
          value={newGroup.nom}
          onChange={(e) => setNewGroup({ ...newGroup, nom: e.target.value })}
          margin="normal"
        />
        <Button variant="contained" color="primary" onClick={handleCreate}>
          Creer
        </Button>
      </Box>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Nom</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {groups.map((group) => (
              <TableRow key={group.id}>
                <TableCell>
                  {editGroup && editGroup.id === group.id ? (
                    <TextField
                      value={editGroup.nom}
                      onChange={(e) =>
                        setEditGroup({ ...editGroup, nom: e.target.value })
                      }
                    />
                  ) : (
                    group.nom
                  )}
                </TableCell>
                <TableCell>
                  {editGroup && editGroup.id === group.id ? (
                    <Button onClick={() => handleUpdate(group.id)}>
                      Enregistrer
                    </Button>
                  ) : (
                    <Button onClick={() => setEditGroup(group)}>
                      Modifier
                    </Button>
                  )}
                  <Button onClick={() => handleDelete(group.id)}>
                    Supprimer
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Container>
  );
};

export default GroupesPage;
